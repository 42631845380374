@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baumans&display=swap');



    /*theme lornc*/
    /*.top-bar,#containerNavbarCenter {*/
    /*    background: #153429 !important;*/
    /*}*/
    /*#containerNavbarCenter{*/
    /*    background: #90591a !important;*/
    /*}*/
    /*#navbar .navbar-nav > li > a {*/
    /*    color: #90591a !important;*/
    /*}*/
h1 {
    color: #d49618 !important;
    font-size: 3rem !important;
    font-family: "Poppins", Arial, sans-serif !important;
    /*font-family: "Baumans", serif !important;*/
}

h2 {
    /*font-family: "Baumans", serif !important;*/
    font-weight: bold !important;
    font-size: 2.4rem !important;
}

h3 {
    font-size: 1.3rem !important;
    text-transform: uppercase;
    font-family: "Poppins", Arial, sans-serif !important;
}
h4{
    font-family: 'Poppins', sans-serif !important;
}

h2.rh_property__heading.mt-4 {
    font-size: 1.8rem !important;
}


/*h5 {*/
/*    font-size: 30px;*/
/*    font-weight: 600;*/
/*    letter-spacing: 0px;*/
/*    color: white;*/
/*}*/
h6 {
    font-family: "Playfair Display", serif;
    color: white;
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: 20px;
}




h5 {
    font-size: 0.9rem;
}


p, ul li {
    font-family: "Poppins", Arial, sans-serif !important;
    font-size: 0.9rem !important;
    line-height: 1.9;
    color: #1d1d1e;

}

#h2-service-provide-item {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
    /*color: #7b6a58 !important;*/
    color: #333 !important;
    margin-bottom: 50px;
}

.main-navbar .navbar-nav .nav-item .nav-link {
    font-size: 14px !important;
    text-transform: uppercase;

}

.navbar-nav .nav-item:hover::before {
    width: calc(100% - 50px);
    background-image: -moz-linear-gradient(0deg, #2a4b44 0%, #2a4b44 100%);
    background-image: -webkit-linear-gradient(0deg, #2a4b44 0%, #2a4b44 100%);
    background-image: -ms-linear-gradient(0deg, #2a4b44 0%, #2a4b44 100%);
}


#navbar .navbar-nav > .active > a {
    color: #d49618 !important;
}

.hero-section {
    position: relative;
    height: 600px;
    overflow: hidden;
}

.hero-section p {
    line-height: 2;
}

/* Panneau de texte à gauche */
.text-panel {
    background-color: #181818; /* Couleur brune avec transparence */
    color: white;
    padding: 3rem;
    flex: 0 0 40%; /* Panneau occupe 50% */
    z-index: 2; /* Assure le chevauchement sur l'image */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border: 1px solid #b1872e;
}

/* Image à droite */
.image-panel {
    flex: 0 0 70%; /* Image occupe 60% de la largeur */
    background: url('/public/assets/img/57006/pers-ext-v01.jpg') center/cover no-repeat;
    position: relative;
    margin: 0 50px 0 0;
    left: -10%;
}

/* Bouton stylisé */
.text-panel .btn {
    margin-top: 1.5rem;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    border: none;
    width: 300px;
    padding: 10px;
    font-size: 1rem;
}

.text-panel .btn:hover {
    background-color: #ebeaea;
    color: #fff;
}

.info-section {
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1rem;
    margin-top: 2rem;
}

.text-content {
    flex: 1;
    padding-right: 2rem;
}

.images-content {
    display: flex;
    align-items: flex-end;
}

.image-box {
    position: relative;
    border-radius: 0.5rem;
}

.image-box img {
    height: auto;
    display: block;
    position: relative;
    z-index: 999;
}

.info-card {
    width: 100%;
    padding: 2rem 2rem 0.7rem 2rem;
    background-color: #2a4b44;
    color: #b1c5c0;
    font-size: 0.9rem;
    width: 280px;
    transform: translate(-50%, 0%);
    left: 50% !important;
    border-radius: 10px;
}

.info-card p, .info-card h5, .info-card li {

    color: white;

}

.info-card li {
    font-size: 0.85rem !important;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
}

.info-card li:last-child {
    border-bottom: none; /* Supprime la bordure pour le dernier élément */
    padding-bottom: 0px !important;
}

/*.top-bar,#containerNavbarCenter{*/
/*    background-color: #2a4b44 !important;*/
/*}*/

.bg-brown {
    background-color: #A55D18;
}

.divider {
    width: 80px;
    height: 2px;
    background-color: #d49618;
    margin: 1rem 0;
}

.action-button {
    display: inline-block;
    margin-top: 1.5rem;
    padding: 0.8rem 1.5rem;
    font-size: 0.8rem;
    color: #333;
    border: 1px solid #333;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.action-button:hover {
    background-color: black !important;
    color: white;
}

#house-img {
    margin: 0px 0px 50px 0px;
}


.item img {
    margin-bottom: 10px;
    opacity: 0.7;
}

#contact-form-sidebar {
    height: 100%;
    background-image: url('/public/assets/img/57006/reunion.jpg');
}

#demo-form {
    background: white;
    padding: 80px 0;
}

#demo-form input:not(#react-select-2-input,#react-select-3-input), #demo-form select, #demo-form textarea {

    background: #f5f5f5 !important;
    border: 1px solid #d2d2d2 !important;
    color: grey !important;
}

#footer-container {
    /*background: #d9d9d9 !important;*/
    background: black !important
}

.footer-bs .footer-brand p, .footer-bs a, .footer-bs p {
    color: rgb(180 180 180) !important;
    /*color: #333 !important;*/
}

h4, .text-brown {
    /*color: #7b6a58;*/
    font-size: 1.5rem;
}

.nav-item:focus > a, .nav-item:hover > a {
    color: #d49618 !important;
}

.slick-track {
    margin-left: 0 !important;
}

.bg-secondary {
    background-color: #f3f3f3 !important;
}

.bg-gold {
    background-color: rgb(161, 100, 39);
}

.info-section h2 {
    color: #d49618 !important;
    font-family: "Playfair Display", serif;
    margin-bottom: 10px !important;

}

.info-section h3 {
    font-size: 2rem !important;
    color: #1d1d1e;
    font-weight: bolder;
}

#target-section h4 {
    font-size: 1rem !important;
    font-weight: 700;
}

.info-section h4 {
    font-family: "Poppins", Arial, sans-serif !important;
text-transform: uppercase;
    font-size: 1.3rem !important;
    color: #1d1d1e;
    font-weight: normal !important;

}

#informations-list li {
    font-size: 0.9rem;
    padding: 0 0 10px 0;
    list-style-type: disc;
    color: white !important;
}

h5.text-uppercase.mb-3 {
    color: #d49618 !important;
}

/*.steps {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    margin-top: 1rem;*/
/*}*/
/*.step-circle {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    border: 2px solid #A55D18;*/
/*    border-radius: 50%;*/
/*    background-color: white;*/
/*    margin: 0 0.5rem;*/
/*}*/
/*.step-circle.active {*/
/*    background-color: #A55D18;*/
/*}*/

.program-progress-bar {
    display: flex;
    align-items: center;
}

.program-step {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.circle {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    border-radius: 50%;*/
/*    border: 2px solid #bfbfbf;*/
/*    background-color: white;*/
/*    display: flex*/
/*;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    font-size: 16px;*/
/*    font-weight: bold;*/
/*}*/

.line {
    width: 150px;
    height: 2px;
    background-color: #bfbfbf;
}

.active.line {
    background-color: #d49618;
}

.program-step.active .circle {
    color: white;
    border-color: #d49618;
    font-size: 12px;
}

.program-step:last-child .line {
    display: none; /* Cacher la ligne après le dernier cercle */
}

.surtitle {
    color: #d49618 !important;
}

img#logo {
    height: 90px !important;
}

.section-title {
    font-weight: bold;
    font-size: 24px;
}

.download-btn {
    background-color: #4c443b; /* Couleur bleue du bouton */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
}

.download-btn:hover {
    background-color: #352f29;
}

.value-item {
    margin-top: 30px;
}

.value-item h3 {
    font-size: 20px;
    font-weight: bold;
}

.value-item p {
    font-size: 16px;
    line-height: 1.5;
}

.magazine-image {
    width: 100%;
    position: relative;
    margin-top: -50px;
    margin-left: 20px;
    border-radius: 10px;
}

.magazine-wrapper {
    position: relative;
    z-index: 88;
}

#why-choose-us-section .value-item, #why-choose-us-section h2, #why-choose-us-section button {
    z-index: 88;
}

.mfn-icon-box-start {
    align-items: baseline;
}

.mfn-icon-box {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}

.background-number {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 200px;
    color: rgba(255, 255, 255, 0.1);
    z-index: 1;
}

/*#why-choose-us-section{*/
/*    background-color: #db8f2a !important;*/
/*    border-radius: 15px;*/
/*    margin: 80px 0 70px 0;*/
/*    padding-bottom:40px ;*/
/*    position: relative;*/
/*}*/
#why-choose-us-section {
    background-color: #7b6a58;
    border-radius: 15px;
    margin: 80px 0 70px 0;
    padding-bottom: 40px;
    position: relative;
}

/*#why-choose-us-section h2{*/
/*color: black !important;*/
/*}*/

.gogo::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background-image: url(https://themes.muffingroup.com/be/nursinghome/wp-content/uploads/2021/08/nursinghome-wrap-bg1.webp);*/
    background-image: url(https://themes.muffingroup.com/be/business7/wp-content/uploads/2023/03/bebusiness7-footer-bg.svg);

    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 5;
}

#why-choose-us-section .value-item p {
    color: rgba(255, 255, 255, 0.5);
}

#why-choose-us-section h3 {
    color: white;
}

.section-title {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
}

.number-wrapper {
    font-size: 2rem;
    margin: 0 20px 0 0;
    line-height: 1;
}

.mfn-icon-box-start {
    align-items: flex-start;
}

.text-gold {
    color: #efc58b !important;
}

#demo-form {
    background-image: url('/public/assets/img/57006/contact-section-bg.jpg') !important;

}

.form-contact {
    background: white;
    padding: 10px 49px 20px;
    border-radius: 20px;
}

#demo-form {
    padding: 40px 0 !important;
}

#demo-form h6 {
    font-size: 14px;
}

#demo-form h3 {
    color: #7b6a58;
    font-size: 38px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
}

#sidebar h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

#demo-form h4 {
    font-size: 28px;
    color: white;
}


.text-beer {
    color: #d49618 !important;
}

.bg-beer {
    background-color: #d49618 !important;
}

.col-md-6.text-center.text-md-start.ps-5 {
    z-index: 88;
}

.rh_banner__image {
    height: 25rem;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.rh_banner {
    display: flex;
    align-items: flex-end;
    width: 100%;
    background: #303030;
    position: relative;
    margin-top: 145px;
}

.rh_banner__cover {
    opacity: .5;
}

/*.rh_banner__cover {*/
/*    background: #0054a6;*/
/*}*/
.rh_banner__cover {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0,100);
}


#wrapper-login-page {
    margin-top: 200px !important;
}

/*.page-title-wrap {*/
/*    position: absolute;*/
/*    bottom: 0px;*/
/*    background: white;*/
/*    left: 0;*/
/*    max-width: 1320px;*/
/*    right: 0;*/
/*    margin: auto;*/
/*    padding: 20px 30px;*/
/*}*/

.page-title-wrap {
    position: relative;
    background: white;
    max-width: 1320px;
    margin: auto;
    padding: 20px 30px;
}

.mn-220 {
    margin-top: -50px;
}

.mn-100 {
    margin-top: -100px;
}

.page-title-wrap h1 {
    font-size: 2.5rem !important;
    color: #333 !important;
}


.bg-success {
    /*background: #77c720 !important;*/
    background: #376259 !important;
}

.item-price {
    font-size: 30px !important;
    color: black;
    text-align: left;
    padding: 0 0 0 2rem;
    border-left: 1px solid rgba(128, 128, 128, .2);
    font-weight: 600;
}

#program-content-wrap {
    padding: 0 30px;
}

.rh_page__property_price {
    text-align: left;
    border-left: 1px solid rgba(128, 128, 128, .2);
}

.rh_page__property_price .price {
    color: #d49618 !important;
    font-size: 2.1rem !important;
    line-height: 1.5;
    margin-bottom: 0;
}

.breadcrumb {
    background: none !important;
    margin-bottom: 0;
}

#program-header-wrap h1 {
    margin-bottom: 0px !important;
}

.rh_banner rh_banner__image {
    position: relative;
}


.card {
    box-shadow: 0px 5px 70px 0px rgb(38 42 76 / 0.2);
}

#sidebar .form-label {
    color: #333;
}

#sidebar input {
    border: none !important;
    outline: none;
    width: 100%;
    border-bottom: 2px solid #f0f0f0 !important;
    color: #333;
    font-weight: 500;
    font-family: inherit;
    font-size: 13px;
    transition: .2s all linear;
}

#sidebar #react-select-2-input {
    height: auto !important; /* Laisser la hauteur par défaut de React Select */
    font-size: 13px !important;
}

#sidebar label {
    font-size: 13px;
}

#sidebar .form-control {
    padding: .375rem 0rem;
    border-radius: 0px;
}

textarea#comment {
    font-size: 13px;
}


#sidebar .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: transparent !important;
    border-bottom-color: #2a4b44 !important;
    outline: 0;
    box-shadow: none !important;
}

.rh_ultra_prop_card_meta_wrap {
    position: relative;
    z-index: 999;
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 12px;
    display: inline-flex;
    flex-wrap: wrap;
}

.rh-ultra-meta-icon-wrapper svg {
    width: 24px;
}

.rh_ultra_prop_card__meta {
    border-radius: 7px;
    padding: 17px 20px;
    transition: 0.2s all linear;
    background: #f3f1ef;
    /*background: #fdf4e7;*/
}

.rh-ultra-dark {
    fill: #d49618;
}

.rh-ultra-green {
    fill: rgb(75 157 139);
}


.rh-ultra-light {
    fill: #fdf4e7;
}

span.rh-ultra-meta-label {
    margin-bottom: 5px;
    display: block;
    font-size: 0.9rem;
}

#program-content-wrap h3 {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    /*font-weight: bolder;*/
    color: #333;
}

.slick-slide img {
    border-radius: 10px;
}

#comment {
    text-align: left !important;
    border: 1px solid #e5e4e4;
    font-size: 13px;
}

/*#demo-form input, #demo-form select {*/
/*    height: inherit !important;*/
/*    background: inherit !important;*/
/*    !*border: 1px solid #242222;*!*/
/*    !*color: grey !important;*!*/
/*}*/

#demo-form #availability input {
    height: inherit !important;
    opacity: 0 !important;
    font-size: 0.9rem !important;
}

[data-aos="custom-zoom"] {
    transform: scale(0.8); /* Taille de départ à 70% */
    opacity: 0; /* Optionnel : rendre invisible avant animation */
    transition: transform 1s ease-out, opacity 1s ease-out;
}

[data-aos="custom-zoom"].aos-animate {
    transform: scale(1); /* Taille finale à 100% */
    opacity: 1; /* Optionnel : rendre visible après animation */
}



.page-head-inner {
    color: white !important;
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 0;
    padding: 15px;
}

.title-wrap {
    position: relative;
    height: 100%;
    display: flex;

    align-items: center;
}

.oooo {
    display: flex;
    justify-content: space-between;
}

/*.page-head-inner {*/
/*    top: 60px;*/
/*    position: absolute;*/
/*    color: white !important;*/
/*    z-index: 99;*/
/*}*/

h1.rh_banner__title {
    color: white !important;
    font-size: 2.5rem !important;
    margin-bottom: 0px !important;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 5px;
}

.text-dark-lemon-lime {
    color: #77C720;
}

.rh-ultra-overview-box {
    display: flex;
    align-items: center;
    -moz-column-gap: 25px;
    column-gap: 25px;

}

.page-title h3 {
    margin-bottom: 0 !important;
}

span.label-featured.label {
    background: #d49618;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.table > tbody {
    font-size: 0.9rem;
}

.social-share-container {
    align-items: flex-end;
    display: flex;
}

.social-share-icon {
    font-size: 24px;
    cursor: pointer;
}

.social-share-bar {
    position: absolute;
    top: 40px; /* Position sous l'icône principale */
    right: 0;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    z-index: 10;
}

.social-share-bar a {
    text-decoration: none;
    font-size: 20px;
    color: #555;
}

.social-share-bar li {
    padding: 10px;
}

.social-share-bar li.social-x {
    background: black;
}

.social-share-bar li.social-fb {
    background: #3b5998;
}

.social-share-bar a:hover {
    color: #000;
}

.rh_property--borderBottom {
    padding: 0 0 1.2rem 0;
    border-bottom: 1px dashed rgba(128, 128, 128, .2);
}

.progress-steps {
    column-gap: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin: 20px 0;
}


.progress-steps .completed {
    color: white;
    border-color: #d49618 !important;
    font-size: 12px;
}

.progress-steps .entire {

    background-color: #d49618 !important;

}


.progress-steps .active p {
    color: #d49618 !important;

}

.progress-steps .line.completed {

    background-color: #d49618 !important;

}

/* Étape */
.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    opacity: 1 !important;
}


/* Cercle actif ou complété */
.step.completed .circle {
    height: 1px;
    flex-grow: 1;
    background-color: #e0e0e0;
    z-index: 0;
}

/* Texte sous les cercles */
.step p {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
    text-align: center;
    white-space: nowrap;
    font-size: 0.8rem !important;
    text-transform: uppercase;
}

/* Ligne entre les cercles */
.line {
    height: 1px;
    flex: 1;
    background-color: #e0e0e0;
    z-index: 0
}

/* Ligne active */
.step.completed + .line {
    background-color: #e0e0e0; /* Couleur verte pour les lignes complétées */
}

.step {

    border: 1px solid #bbbbbb !important;

    background-color: transparent !important;


}

#programs-list-page {
    background-color: white;
    position: relative;
}

/*div#page-contact-wrap {*/
/*    margin-top: -140px;*/
/*    z-index: 99;*/
/*    position: relative;*/
/*}*/

h1 span {
    display: block;
}

.banner-text-content h1 {
    font-weight: 300;
}

.text-panel h1 {
    font-size: 60px !important;
    text-transform: uppercase;
    color: #d49618 !important;
    font-family: "Baumans", serif !important;
}

.text-metalic-silver {
    color: #9cb1ac;
}

.bg-japanese-indigo {
    background-color: #2a4b44 !important;
}

.values-section {
    /*background-color: #7b6a58; !* Couleur marron du fond *!*/
    background-color: #2a4b44;
    color: #fff; /* Couleur texte par défaut */
    padding: 60px 20px;
    background-image: url('/public/assets/img/57006/famille-heureuse.jpg');
    background-position: top right;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 80px;
}

.values-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(https://themes.muffingroup.com/be/business7/wp-content/uploads/2023/03/bebusiness7-footer-bg.svg);
    background-size: cover;
    background-position: center;
    opacity: 0.1;
}

.value-card {
    background-color: #fff; /* Fond blanc pour les cartes */
    color: #000; /* Texte noir pour les cartes */
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.value-card h5 {
    color: #d49618;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 1rem;
}

.value-card p {
    font-size: 0.92rem !important;
}

.right-image img {
    max-width: 100%;
    border-radius: 8px;
}

#item-values-wrap {
    z-index: 1;
    position: relative;
}

#item-values-wrap path {
    stroke: #333;
}

#sidebar-contact-form-container {
    position: sticky !important;
    top: 0;
}
#page-contact-wrap h3{
    font-weight: 600;
    font-size: 1.7rem !important;
}

header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    background: white;
}


#page-wrapper {
    margin-top: 167px;
}


.main-navbar{
    height: 116px;
}



/*.nav-item:last-child {*/
/*    border-right: none; !* Supprime la bordure du dernier élément *!*/
/*}*/



@media (max-width: 575px) {



    .text-panel {
        background-color: #181818;
        color: white;
        padding: 1rem;
        z-index: 2;
        display: flex;
        flex: 100 100 0%;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border: 1px solid #b1872e;
        width: 100% !important;
    }

    .text-panel .btn {

        width: 100% !important;

    }

    #page-wrapper {
        margin-top: 150px !important;
    }


    .hero-section {
        position: relative;
        height: 400px;
        overflow: hidden;
    }

    img#logo {
        height: 60px !important;
    }

}




@media (min-width: 1200px) {

    .bottom-xl-negative {
        bottom: -55px !important;
    }
    .banner-title-name {
        width: 840px;
    }

}



@media (min-width: 1200px) {

    .mg-xl-150 {
        margin-bottom: 150px !important;
    }
}

@media (max-width: 992px) {

    .navbar-header {
        max-width: 50px !important;
        border-right: 1px solid #ebe5e5;
    }

        .image-box:after {
            content: none !important; /* Supprime l'élément pseudo */
            border: none !important;  /* Supprime la bordure */
            background-color: transparent !important; /* Assure la transparence */
        }

    .line {
        height: 50px !important;
        background-color: #e0e0e0;
        z-index: 0;
        width: 1px !important;
    }

    .progress-steps {
        height: 300px;
    }

    .progress-steps p {
        margin-right: 200px!important;
        margin-top: 0px!important;

    }
}


.table-responsive {
    position: relative;
}

.scroll-top {
    overflow-x: auto; /* Barre de défilement horizontal */
    margin-right: -16px; /* Superposition avec le tableau */
    /*height: 16px; !* Hauteur minimale pour afficher la barre *!*/
}

.scroll-top::-webkit-scrollbar {
    height: 8px; /* Taille de la barre de défilement */
}

.scroll-top::-webkit-scrollbar-thumb {
    background: #888; /* Couleur de la barre */
    border-radius: 4px;
}

.scroll-top::-webkit-scrollbar-thumb:hover {
    background: #555; /* Couleur au survol */
}

.social-share-icon {
    font-size: 24px;
    cursor: pointer;
    background: #d49618;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-share-icon svg{
    fill: #ddb86e;
    stroke: #f0f0f0;
}

#demo-form input {
    height: inherit !important;
}

.image-box:after {
    content: "";
    position: absolute;
    top: 70px;
    left: -70px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 10px solid rgba(0, 0, 0, 0.1);
}

 .banner-title-outer {
    position: relative;
    padding: 70px 0px 70px 30px;
    border: 10px solid #fff;
    border-right: 0px;
    width: 200px;
}


.banner-title-outer:before {
    bottom: 0px;
    right: 0px;
}

.banner-title-outer:after {
    top: 0px;
}

.banner-title-outer:after,  .banner-title-outer:before {
    content: "";
    position: absolute;
    right: 0px;
    width: 10px;
    height: 50px;
    background-color: #fff;
}

.black-overlay {
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

div#plan-widget {
    border: 1px solid #d49618;
}

#plan-widget h3{
    z-index: 99;
    position: absolute;
    color: #d49618;
    font-size: 2rem !important;
    max-width: 300px;
    text-align: center;
}

#plan-widget h3 {
    display: inline-block;
    font-size: 20px;
    transition: transform 0.3s ease;
}

#plan-widget h3:hover {
    animation: pulse 0.4s ease forwards, colorChange 0.6s ease forwards;
}

@keyframes colorChange {
    0% { color: white; }
    50% { color: white; } /* Couleur au moment de l'agrandissement */
    100% { color: white; } /* Retour à la couleur normale */
}

@keyframes colorChange {
    0% { color: #d49618; }
    50% { color: #f8d180; } /* Couleur au moment de l'agrandissement */
    100% { color: #d49618; } /* Retour à la couleur normale */
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.3); }
    100% { transform: scale(1); }
}

.sweetalert-image .swal2-popup {
    max-width: 80vw;  /* Limite la largeur de la modal */
    max-height: 80vh; /* Limite la hauteur de la modal */
}

.sweetalert-image .swal2-image {
    object-fit: contain; /* Maintient l'aspect ratio de l'image */
}

div:where(.swal2-container) div:where(.swal2-popup) {
    width: auto !important;   /* Supprime la largeur forcée */
    max-width: 90vw !important;  /* Permet à l'image d'occuper 90% de la largeur de l'écran */
    max-height: 90vh !important; /* Permet à l'image d'occuper 90% de la hauteur de l'écran */
    padding: 0 !important;       /* Évite un padding excessif */
}


.video-thumbnail-container {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.video-thumbnail-large-container {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.video-thumbnail {
    display: block;
    width: 100%;
    border-radius: 8px;
}

.slider-for .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 2em;
    padding: 15px 20px;
    border-radius: 50%;
    pointer-events: none;
}


.slider-nav .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 1.5em;
    padding: 5px 10px;
    border-radius: 50%;
    pointer-events: none;
}


.video-slide {
    position: relative;
    width: 100%;
}

/* Style de l'iframe, avec ratio 16:9 */
.video-container {
    position: relative;
    width: 100%;
    padding-top: 76.25%; /* Ratio 16:9 */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {
    width: 600px !important;
    padding-bottom: 50px !important;
}

#freehome-mobile-menu{
    margin-top: 100px !important;
}

.legal-notices-page ul li {
    color: #1d1d1e !important;
font-family: "Poppins", Arial, sans-serif !important;
    font-weight: 300;
}




